<template lang="pug">
v-app
  v-content
    v-container(fluid, fill-height)
      v-layout(align-center, justify-center)
        v-flex(xs12, sm12, md12, lg12).text-xs-center.mb-5
            .layout.column.align-center.mb-2
                  img(:src="logoColor", alt='Automatic 4', width='250').mb-5
            h1.display-4.display-4.font-weight-bold 401!
            h3.font-weight-bold  Ops! Você não tem permissão para acessar a página solicitada.
            v-btn(flat, color="success", type="button", :to="{ name: 'Dashboard' }")
</template>

<script>
import Hashes from 'jshashes';
import logoColor from '@/assets/images/logo.png';
// eslint-disable-next-line
const SHA512 = new Hashes.SHA512;

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    loading: false,
    model: {},
    dialogSenha: false,
    logoColor: logoColor
  }),
  methods: {
   async sendLogin(model) {
      const vm = this;
      try {
        const response = await vm.$axios.post("/login", { codigo: model.codigo, senha: SHA512.hex(model.senha) });
        vm.$store.dispatch("setUser", response.data);
        window.getApp.$emit("APP_LOGIN_SUCCESS");
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : "Ocorreu um erro ao fazer o login. Tente novamente!";
        window.getApp.$emit("APP_ALERT", { color: "red", text: err });
        vm.loading = false;
      }
    },
    async sendRecovery(model) {
      const vm = this;
      try {
        const response = await vm.$axios.put("/password/reset", { email: model.email });
        vm.dialogSenha = false;
        window.getApp.$emit("APP_ALERT", { color: "success", text: `Uma nova senha foi gerada e enviada para o email ${model.email}.` });
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : "Ocorreu um erro ao recuperar a senha. Tente novamente!";
        window.getApp.$emit("APP_ALERT", { color: "red", text: err });
        vm.loading = false;
      }
    },
    async login() {
      const vm = this;
      try {
        vm.loading = true;
        const result = await vm.$validator.validateAll('login');
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm.sendLogin(vm.model);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async recovery() {
      const vm = this;
      try {
        vm.loading = true;
        const result = await vm.$validator.validateAll("formRecovery");
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm.sendRecovery(vm.model);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    }
  }
};
</script>
<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
